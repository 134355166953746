import React, { useReducer } from 'react'
import styled from 'styled-components'
import Collapse from '@material-ui/core/Collapse'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'
import Container from 'components/Container'

const Category = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 96px;
`
const CategoryDescription = styled.div`
    border-top: 3px solid ${({ theme }) => theme.colors.base.blue};
    padding-top: 8px;
    ${mediaQuery()({ max: 'width: 280px; margin-right: 200px;', tablet: 'width: 100%; margin-bottom: 32px;', mobile: 'margin-right: 0;' })}
`

const CategoryTitle = styled.h3`
`

const CategorySubtitle = styled.p``

const Questions = styled.div`
    flex: 1;
`

const QuestionContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid black;
    &:first-child {
        border-top: 1px solid black;
    }
`

const QuestionTitle = styled.div`
    padding: 8px 8px 8px 0;
    font-weight: bold;
    display: flex;
    color: black;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
`

const QuestionDescription = styled.div`
    padding: 8px 0;
`

const Arrow = styled.div`
    height: 1rem;
    width: 1rem;
    border-left: 1px solid ${({ theme }) => theme.colors.base.orange};
    border-bottom: 1px solid ${({ theme }) => theme.colors.base.orange};
    transform: ${({ open }) => (open ? 'translateY(25%) rotate(-225deg)' : 'translateY(-25%) rotate(-45deg)')};
    transition: transform 0.3s ease-in-out;
`

function Question ({ title, description }) {
  const [open, toggle] = useReducer(state => !state, false)
  return (
    <QuestionContainer>
      <QuestionTitle onClick={toggle}>{title}<Arrow open={open} /></QuestionTitle>
      <Collapse in={open}>
        <QuestionDescription>
          <RemarkRenderer ast={description?.childMarkdownRemark?.htmlAst} />
        </QuestionDescription>
      </Collapse>
    </QuestionContainer>
  )
}

export default function StoryblokFAQ ({ data, ...props }) {
  return (
    <Container extraWide {...props}>{
        data.categories.map(({ title, subtitle, questions }) => (
          <Category>
            <CategoryDescription>
              <CategoryTitle>{title}</CategoryTitle>
              <CategorySubtitle>{subtitle}</CategorySubtitle>
            </CategoryDescription>
            <Questions>
              {questions.map(q => <Question {...q} />)}
            </Questions>
          </Category>
        ))
    }
    </Container>
  )
}
